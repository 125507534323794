import { graphql } from 'gatsby';
import React from 'react';
import { navSections } from '../components/navbar';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { DisplayTiny, Huge, Small } from '../components/text';
import styled from 'styled-components';
import { Divider } from '../components/LandingPage/Divider';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from 'gatsby-image';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const VALID_LINK_PREFIXES = ['https://', 'http://', 'mailto:'];

export const showcasePageQuery = graphql`
  query ShowcasePageQuery {
    copy: contentfulWhatToDoWithYourName {
      appShowcaseTitle
      appShowcaseSubtitle
      appShowcaseCta {
        json
      }
    }

    handshakeUsecases: allContentfulHandshakeUsecase(
      sort: { fields: spotlight, order: DESC }
      filter: { onlyInAppModal: { nin: true } }
    ) {
      nodes {
        id
        link
        title
        spotlight
        description
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const randomIntInRange = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const ShowcasePage = ({ data: { seo, copy, handshakeUsecases } }) => {
  const meta = usePageMetaAttributes('useCases');

  // Alternate the SEO image between the different spotlight item images
  // Spotlight items have the best/most colorful/custom images.
  const spotlightImages = handshakeUsecases.nodes
    .filter(useCase => useCase.spotlight)
    .map(useCase => useCase.image);
  const seoImage = spotlightImages[randomIntInRange(0, spotlightImages.length - 1)];

  return (
    <Layout currentSection={navSections.PRODUCTS}>
      <SEO
        title={meta.title}
        image={seoImage.file.url}
        imageWidth={seoImage.file.details.image.width}
        imageHeight={seoImage.file.details.image.width}
        description={meta.description}
      />
      <PageHeading>
        <DisplayTiny weight="bold">{copy.appShowcaseTitle}</DisplayTiny>
        <Huge>{copy.appShowcaseSubtitle}</Huge>
        <Divider />
        <Small colored="grey70">{documentToReactComponents(copy.appShowcaseCta.json)}</Small>
      </PageHeading>
      <Gallery>
        {handshakeUsecases.nodes.map(useCase => {
          // If fluid object is missing (for example unsupported image types), Gatsby
          // cannot lazy load the image. Skip in this case, and fix with correct image
          // type in Contentful
          if (!useCase.image.fluid) return null;

          let parsedLink = useCase.link;

          if (!VALID_LINK_PREFIXES.some(prefix => parsedLink.startsWith(prefix))) {
            parsedLink = `//${parsedLink}`;
          }

          return (
            <GalleryItem key={useCase.id}>
              <button tabIndex="-1" onClick={() => (window.location = parsedLink)}>
                <Img
                  css={{ borderRadius: '3px' }}
                  fluid={useCase.image.fluid}
                  alt={`${useCase.title} Handshake app rendition`}
                />
              </button>
              <Small weight="bold" as="a" href={parsedLink}>
                {useCase.title}
              </Small>
              <Small>{useCase.description}</Small>
            </GalleryItem>
          );
        })}
      </Gallery>
    </Layout>
  );
};

const PageHeading = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: ${({ theme }) => theme.spacing(65)}; /* 520px */
  margin-top: ${({ theme }) => theme.spacing(8)};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(7)};
  flex-direction: column;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  a {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  a:hover {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
  }

  ${Divider} {
    margin-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    ${DisplayTiny} {
      font-size: ${({ theme }) => theme.text.header.size};
      line-height: ${({ theme }) => theme.text.header.lineHeight};
    }
    ${Huge} {
      font-size: ${({ theme }) => theme.text.large.size};
      line-height: ${({ theme }) => theme.text.large.lineHeight};
    }
  }
`;

const Gallery = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  margin-bottom: ${({ theme }) => theme.spacing(14)};

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 814px;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 100%;
  }
`;

const GalleryItem = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
  flex-direction: column;

  width: 33%;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 50%;
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    width: 100%;
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  a:hover {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  button {
    margin: 0;
    border: none;
    padding: 0;
    background: none;
  }

  button:hover {
    * {
      opacity: 0.95;
    }
  }
`;

export default ShowcasePage;
